import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DdtTableView } from 'pages/DdtPage/DdtTableView'
import { fetchDdts, FetchDdtsResponseType } from 'pages/DdtPage/thunks'
import { combineReducers } from 'redux'
import filters, { searchFilterSlice } from './FilterBlock/slices'
import { PageStatistics } from './PageStatistics'


export const ddtTablePageSlice = createSlice<number, SliceCaseReducers<number>>({
  name: 'ddtTablePage',
  initialState: 1,
  reducers: {
    setPage(state, action) {
      return action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(searchFilterSlice.actions.setTextInput, () => {
      return 1
    })
  }
})

// Extract the action creators object and the reducer
const {actions} = ddtTablePageSlice
// Extract and export each action creator by name
export const {setPage} = actions

const pageStatisticsInit = {
  firstResultIndex: 0,
  lastResultIndex: 0,
  totalResults: 0,
  totalPages: 10
}

const initialValue = {
  loading: false,
  tableViewList: [],
  pageStatistics: pageStatisticsInit,
  error: ''
}

type DdtSliceInit = {
  loading: boolean,
  tableViewList: DdtTableView[],
  pageStatistics: PageStatistics,
  error: string
}

export const ddtsDataSlice = createSlice({
  name: 'ddtsData',
  initialState: initialValue as DdtSliceInit,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDdts.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchDdts.fulfilled, (state, action: PayloadAction<FetchDdtsResponseType>) => {
      state.loading = false
      state.tableViewList = action.payload.tableViewList
      state.pageStatistics = action.payload.pageStatistics
      state.error = ''
    })
    builder.addCase(fetchDdts.rejected, (state, action) => {
      state.loading = false
      state.tableViewList = []
      state.error = action.error.message || 'Something went wrong'
    })
  }
})

export default combineReducers({
  'data': ddtsDataSlice.reducer,
  'filters': filters
})
