import { DurationFilterItem } from 'pages/DdtPage/FilterBlock/DurationFilterItem'
import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { startDateFilterSlice } from 'pages/DdtPage/FilterBlock/slices'
import { useDispatch } from 'react-redux'
import Calendar from 'pages/DdtPage/FilterBlock/Calendar'

enum DurationFilterItemType {
  TODAY, LAST_7_DAYS, LAST_MONTH, CUSTOM, ALL
}

export const DurationFilter = (props: any) => {

  const [selectedFilterItem, setSelectedFilterItem] = useState(DurationFilterItemType.ALL)

  const updateFilterItem = (item: DurationFilterItemType) => {
    if(selectedFilterItem === item){
      setSelectedFilterItem(DurationFilterItemType.ALL)
    } else {
      setSelectedFilterItem(item)
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    let startOfDay = null
    switch (selectedFilterItem) {
      case DurationFilterItemType.TODAY:
        startOfDay = moment().startOf('day').toISOString()
        break;
      case DurationFilterItemType.LAST_7_DAYS:
        startOfDay = moment().startOf('day').subtract(7, 'days').toISOString()
        break;
      case DurationFilterItemType.LAST_MONTH:
        startOfDay = moment().startOf('day').subtract(1, 'month').toISOString()
        break;
    }
    dispatch(startDateFilterSlice.actions.setStartDate(startOfDay))
  }, [selectedFilterItem, dispatch])

  return (
    <>
      <div className="rounded-md ml-auto">
        <DurationFilterItem className="rounded-l-md" label="Today"
                            onClick={() => updateFilterItem(DurationFilterItemType.TODAY)}
                            isActive={selectedFilterItem === DurationFilterItemType.TODAY}/>
        <DurationFilterItem className="" label="Last 7 Days"
                            onClick={() => updateFilterItem(DurationFilterItemType.LAST_7_DAYS)}
                            isActive={selectedFilterItem === DurationFilterItemType.LAST_7_DAYS}/>
        <DurationFilterItem className="" label="Last Month"
                            onClick={() => updateFilterItem(DurationFilterItemType.LAST_MONTH)}
                            isActive={selectedFilterItem === DurationFilterItemType.LAST_MONTH}/>
        <DurationFilterItem className="rounded-r-md" label="Custom"
                            onClick={() => updateFilterItem(DurationFilterItemType.CUSTOM)}
                            isActive={selectedFilterItem === DurationFilterItemType.CUSTOM}/>
      </div>
      {selectedFilterItem === DurationFilterItemType.CUSTOM && <Calendar/>}
    </>
  )
}
