import { Header } from 'components/Header'
import { Footer } from 'components/Footer'

export default function Page(props: any) {
  return (
    <>
      <Header />

      <main className="flex flex-col">
        {props.children}
      </main>
      <Footer />
    </>
  )
}
