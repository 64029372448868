import { JsonViewer } from '@textea/json-viewer'
import { useState } from 'react'
import { getResponsePidData } from 'pages/QueryPage/pidDataApi'
import { Container } from 'components/Container'
import logger from '../../services/logger-service'


export default function RequestInput() {
    const environment = 'STAGE'
    const [requestString, setRequestString] = useState('CAN-C-220107-747-4C7')
    const [jsonResponse, setJsonResponse] = useState()

    async function retrievePidJsonData() {
        try {
            const axiosResponse = await getResponsePidData(requestString)
            setJsonResponse(axiosResponse.data)
            logger.log('Query Results', axiosResponse.data)
        } catch (e) {
            // Clear the data if there is no such record
            setJsonResponse(undefined)
        }
    }

    return (
        <>
            <Container className="w-full">
                <div className="flex">
                    <div className="flex-1">
                        <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                            Target Environment
                        </label>
                        <div className="flex">
                            <div className="flex rounded-md shadow-sm grow">
                              <span
                                  className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                {environment}
                              </span>
                                <input
                                    type="text"
                                    name="company-website"
                                    id="company-website"
                                    className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="CAN-C-2203DB-7E0-7E8?isResponse=true"
                                    value={requestString}
                                    onChange={(e) => setRequestString(e.target.value)}
                                />
                            </div>
                            <div className="flex">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md border border-r-0 border-cyan-500 bg-cyan-500 px-3 ml-3 text-white hover:bg-cyan-700"
                                    onClick={retrievePidJsonData}
                                > Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-hidden rounded-lg bg-gray-50 mt-3">
                    <div className="px-4 py-2 sm:p-6" style={{height:
                            "550px", overflow: "scroll"}}>
                        <JsonViewer value={jsonResponse}/>
                    </div>
                </div>
            </Container>
        </>
    )
}
