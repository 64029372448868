import { FileRecordProcessingStatus } from 'pages/DdtPage/FileRecord/FileRecordProcessingStatus'
import { apiTokenWrapper } from 'api/ApiTokenWrapper'

export const baseUrl = process.env.REACT_APP_BACK_END_URL

const pageSize = 5
export const getFilesTableView = (
  page: number, search ?: string, userSubject ?: string, startDate ?: string, endDate ?: string
) => {
  let queryParamFilters = '';
  if(!!search) {
    queryParamFilters += `&search=${search}`
  }
  if(!!userSubject){
    const userSubjectSplit = userSubject.split('|')
    queryParamFilters += `&userProvider=${userSubjectSplit[0]}`
    queryParamFilters += `&userId=${userSubjectSplit[1]}`
  }
  if(!!startDate) {
    queryParamFilters += `&startDate=${startDate}`
  }
  if(!!endDate) {
    queryParamFilters += `&endDate=${startDate}`
  }
  const url = `/files/table?page=${page}&size=${pageSize}${queryParamFilters}`;
  return apiTokenWrapper.get(url);
}

export const downloadFile = (fileId: any, setDownloading: any) => {
  const url2 = `/files/${fileId}/download`
  setDownloading(true)
  apiTokenWrapper.get(url2, {responseType: 'blob'}).then(res => {
    const fileName = res.headers['file-name']
    const blob = res.data
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    setDownloading(false)

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  })

}

export const uploadFile = (file: any) => {
  const url = `/files/uploadFile`;
  const formData = new FormData();
  // @ts-ignore
  formData.append('file', file);
  // @ts-ignore
  formData.append('fileName', file.name);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  console.log('upload file')
  return apiTokenWrapper.post(url, formData, config);
}

type FileStatus =
  ((prevState: (FileRecordProcessingStatus.DRAFT | FileRecordProcessingStatus.ERROR | FileRecordProcessingStatus.PROCESSING | FileRecordProcessingStatus.PROCESSED)) => (FileRecordProcessingStatus.DRAFT | FileRecordProcessingStatus.ERROR | FileRecordProcessingStatus.PROCESSING | FileRecordProcessingStatus.PROCESSED))
  | FileRecordProcessingStatus.DRAFT
  | FileRecordProcessingStatus.ERROR
  | FileRecordProcessingStatus.PROCESSING
  | FileRecordProcessingStatus.PROCESSED
export const createSseForFileProcessing = (
  fileProcessingId: number,
  setProcessingStatus: (value: FileStatus) => void,
  setErrorMessage: (value: string) => void): EventSource => {
  console.log('createSseForFileProcessing:', fileProcessingId)
  const sse = new EventSource(`${baseUrl}/files/${fileProcessingId}/sse-status/`, {withCredentials: false});
  const listener = (event: { data: string }) => {
    const responseData = JSON.parse(event.data)
    const processingStatus = responseData.processingStatus
    setProcessingStatus(processingStatus);
    setErrorMessage(responseData.errorMessage);
    if(processingStatus === FileRecordProcessingStatus.PROCESSED){
      console.log('sse close PROCESSED:', fileProcessingId)
      sse.close()
    }
  }
  sse.addEventListener(String(fileProcessingId), listener)

  sse.onerror = (error) => {
    // sse.removeEventListener(String(fileProcessingId), listener)
    sse.close()
    console.log('sse close error:', fileProcessingId, error, new Date())
  }
  return sse;
}

export const removeFile = (fileProcessingRecordId: number) => {
  const url = `/files/${fileProcessingRecordId}`;
  return apiTokenWrapper.delete(url);
}
