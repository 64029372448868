import React, { BaseSyntheticEvent, useEffect, useMemo, useRef } from 'react'
import { Container } from 'components/Container'
import * as fileRecordApi from './FileRecord/fileRecordApi'
import { FileRecord } from 'pages/DdtPage/FileRecord/FileRecord'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/ReduxState'
import { FilterBlock } from 'pages/DdtPage/FilterBlock/FilterBlock'
import { fetchDdts } from 'pages/DdtPage/thunks'
import { debounce } from 'lodash'
import logger from '../../services/logger-service'
import Pagination from 'components/Pagination/Pagination'

export default function Table(props: { className: string }) {
  const {className} = props

  const dispatch = useDispatch()
  const ddtsDisplay = useSelector((state: ReduxState) => state.ddts.data.tableViewList)
  // @ts-ignore https://stackoverflow.com/questions/68812319/redux-toolkit-argument-of-type-asyncthunkaction-is-not-assignable-to-param
  const retrieveFileRecords = () => dispatch(fetchDdts())

  const page = useSelector<ReduxState>(state => state.ddtTablePage)
  const searchFilter = useSelector<ReduxState>(state => state.ddts.filters.searchFilter)
  const userFilter = useSelector<ReduxState>(state => state.ddts.filters.userFilter)
  const startDateFilter = useSelector<ReduxState>(state => state.ddts.filters.startDate)

  const debounceCallback = useMemo(() => debounce(() => {
    // @ts-ignore
    return dispatch(fetchDdts())
  }, 300), [dispatch])

  useEffect(() => {
    debounceCallback()
  }, [page, searchFilter, userFilter, startDateFilter, debounceCallback])

  function uploadFile(event: BaseSyntheticEvent) {
    logger.log('Add DDT', {
      'Last Modified': event.target.files[0].lastModified,
      'Last Modified Data': event.target.files[0].lastModifiedDate,
      'File Name': event.target.files[0].name,
      "File Size": event.target.files[0].size,
    })
    const file = event.target.files[0];
    event.preventDefault();
    fileRecordApi.uploadFile(file).then((response) => {
      retrieveFileRecords()
    });
  }

  const fileInput = useRef(null)
  return (
    <div className={clsx("px-4 sm:px-6 lg:px-8", className)}>
      <Container className="flex flex-column h-full">
        <FilterBlock/>

        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">DDTs</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={() => {
                //@ts-ignore
                fileInput.current.click()
              }}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Add DDT
              <input
                ref={fileInput}
                type="file"
                onChange={uploadFile}
                hidden
              />
            </button>
          </div>
        </div>

        <div className="mt-8 flex flex-col grow-0">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Version
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Submitted by
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Submitted date
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                  {ddtsDisplay.map((ddt) => (
                    <FileRecord key={ddt.id} {...ddt} retrieveFileRecords={retrieveFileRecords}/>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="grow"></div>

        <Pagination className="grow-0 mt-2"/>
      </Container>
    </div>
  )
}
