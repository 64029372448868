import { useAuth0 } from '@auth0/auth0-react'
import { userFilterSlice } from 'pages/DdtPage/FilterBlock/slices'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'

export const ShowOnlyFlag = (props: any) => {

  const auth0 = useAuth0()
  const userSubject = auth0.user?.sub

  const [isChecked, setChecked] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(userFilterSlice.actions.setResults(isChecked ? userSubject : null))
  }, [isChecked, userSubject, dispatch])


  return <div className="">
    <label className="px-2">Show only my files</label>
    <input className="text-cyan-600 focus:ring-0"
           type="checkbox"
           onChange={() => setChecked(!isChecked)}
           defaultChecked={isChecked}/>
  </div>
}
