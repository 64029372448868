import clsx from 'clsx'

export const DurationFilterItem = (props: any) => {
  const {label, isActive, className, onClick} = props;
  const backGroundAndTextColor = isActive ? "bg-cyan-600 text-white" : "bg-white-600 text-black"
  return (
      <button
        onClick={onClick}
        className={clsx(className, backGroundAndTextColor,
        "inline-flex items-center justify-center border border-transparent px-4 py-2 text-sm font-medium " +
        "shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 " +
        "focus:ring-offset-2 sm:w-auto")}>
        {label}
      </button>
  )
}
