import React from 'react'
import RequestInput from 'pages/QueryPage/RequestInput'
import Page from 'components/Page'

export default function QueryPage() {

  return (
    <Page>
      <RequestInput/>
    </Page>
  )
}
