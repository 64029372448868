import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import axios, { AxiosRequestConfig } from 'axios'
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js'
import { merge } from 'lodash'

const audience = process.env.REACT_APP_AUTH0_AUDIENCE
const baseURL = process.env.REACT_APP_BACK_END_URL

type GetAccessTokenSilentlyType = {
  (
    options: GetTokenSilentlyOptions & { detailedResponse: true }
  ): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyVerboseResponse | string>;
}

class ApiTokenWrapper {

  public readonly getTokenSilentlyOptions: GetTokenSilentlyOptions = {audience}

  private _tokenGenerator: GetAccessTokenSilentlyType | null;
  private axiosInstance = axios.create({
    baseURL,
    // timeout: 10000,
  });

  constructor(getAccessTokenSilently: null) {
    this._tokenGenerator = getAccessTokenSilently
  }

  public set tokenGenerator(value: GetAccessTokenSilentlyType) {
    this._tokenGenerator = value
  }

  public get tokenGenerator(): GetAccessTokenSilentlyType {
    if(this._tokenGenerator === null) {
      throw new Error('Token generator should be initialized')
    } else {
      return this._tokenGenerator
    }
  }

  async get(url: string, conf?: AxiosRequestConfig) {
    const config = await this.enrichWithToken(conf)
    return this.axiosInstance.get(url, config)
  }

  async post(url: string, data = {}, config = {}) {
    config = await this.enrichWithToken(config)
    return this.axiosInstance.post(url, data, config)
  }

  async delete(url: string) {
    const config = await this.enrichWithToken()
    return this.axiosInstance.delete(url, config)
  }

  private async enrichWithToken(config?: {}) {
    const token = await this.tokenGenerator(this.getTokenSilentlyOptions)
    return merge(config, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

}

export const apiTokenWrapper = new ApiTokenWrapper(null)
