import { combineReducers } from "redux";
import { createSlice } from '@reduxjs/toolkit'

export const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState: '',
  reducers: {
    setTextInput(state, action) {
      return action.payload
    }
  },
})

export const userFilterSlice = createSlice({
  name: 'userFilter',
  initialState: null,
  reducers: {
    setResults(state, action) {
      return action.payload
    }
  },
})

export const startDateFilterSlice = createSlice({
  name: 'startDate',
  initialState: null,
  reducers: {
    setStartDate(state, action) {
      return action.payload
    }
  },
})

export const endDateFilterSlice = createSlice({
  name: 'endDate',
  initialState: null,
  reducers: {
    setEndDate(state, action) {
      return action.payload
    }
  },
})

export default combineReducers({
  'searchFilter': searchFilterSlice.reducer,
  'userFilter': userFilterSlice.reducer,
  'startDate': startDateFilterSlice.reducer,
  'endDate': endDateFilterSlice.reducer
})
