import React, { useState } from 'react'
import { downloadFile } from 'pages/DdtPage/FileRecord/fileRecordApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import styles from 'pages/DdtPage/FileRecord/FileRecord.module.scss'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ReactComponent as DownloadIcon } from 'images/icons/download.svg'

export const Download = (props: any) => {
  const {id, className} = props

  const [isDownloading, setDownloading] = useState(false)

  return (
    isDownloading
      ? <FontAwesomeIcon className={clsx(className, styles.closeButton)}
                         icon={solid('spinner')}/>
      : <DownloadIcon className={className} onClick={() => downloadFile(id, setDownloading)}/>
  )
}
