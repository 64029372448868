import SearchInput from 'pages/DdtPage/FilterBlock/SearchInput'
import { DurationFilter } from 'pages/DdtPage/FilterBlock/DurationFilter'
import { ShowOnlyFlag } from 'pages/DdtPage/FilterBlock/ShowOnlyFlag'

export const FilterBlock = (props: any) => {

  return (
    <div className="flex place-items-center">
      <SearchInput/>
      <ShowOnlyFlag/>
      <DurationFilter/>
    </div>
  )
}
