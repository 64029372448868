import React from 'react'

export const RangeDateInput = (props: any) => {
  const {value, label} = props
  return (
    <div className="relative mt-1 flex items-center p-3 ">
      <input
        type="text"
        name="search"
        id="search"
        className="w-40 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder={label}
        value={value}
        readOnly={true}
      />
    </div>
  )
}
