import { Container } from 'components/Container'

export function Footer() {
  return (
    <>
      <footer className="shrink-0">
        <hr/>
        <Container className="">
          <div className="flex flex-col items-center py-2 sm:flex-row sm:justify-between">

            <p className="mt-6 text-sm text-slate-500 sm:mt-0">
              &copy; 2023 Tweddle Group
            </p>
          </div>
        </Container>
      </footer>
    </>
  )
}
