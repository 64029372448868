import {
  add,
  differenceInDays,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  parseISO,
  setDate,
  startOfMonth,
  sub,
} from "date-fns";
import Cell from "./Cell";
import { useState } from 'react'
import { RangeDateInput } from 'pages/DdtPage/FilterBlock/RangeDateInput'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/ReduxState'
import { endDateFilterSlice, startDateFilterSlice } from 'pages/DdtPage/FilterBlock/slices'

const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

type Props = {
  value?: Date;
  endDateValue?: Date;
  onChange?: (date: Date) => void;
};
//TODO think about disabling future dates

const Calendar: React.FC<Props> = ({ value = new Date(), endDateValue, onChange }) => {

  const [displayValue, setDisplayValue] = useState(value)

  const dispatch = useDispatch()
  const startRange = useSelector((state:ReduxState)=> !!state.ddts.filters.startDate ? parseISO(state.ddts.filters.startDate) : null)
  const setStartRange = (date: (Date | null)) => dispatch(startDateFilterSlice.actions.setStartDate(date?.toISOString()))
  const endRange = useSelector((state:ReduxState)=> !!state.ddts.filters.endDate ? parseISO(state.ddts.filters.endDate) : null)
  const setEndRange = (date: (Date | null)) => dispatch(endDateFilterSlice.actions.setEndDate(!!date ? date.toISOString(): null))

  const startMonthDate = startOfMonth(displayValue);
  const endMonthDate = endOfMonth(displayValue);
  const numDays = differenceInDays(endMonthDate, startMonthDate) + 1;

  const prefixDays = startMonthDate.getDay();
  const suffixDays = 6 - endMonthDate.getDay();

  const prevMonth = () => setDisplayValue(sub(displayValue, { months: 1 }));
  const nextMonth = () => setDisplayValue(add(displayValue, { months: 1 }));
  const prevYear = () => setDisplayValue(sub(displayValue, { years: 1 }));
  const nextYear = () => setDisplayValue(add(displayValue, { years: 1 }));

  const handleClickDate = (index: number) => {
    const clickDate = setDate(displayValue, index)
    if(!!endRange){
      setEndRange(null)
      setStartRange(clickDate)
    } else {
      if(!startRange || isBefore(clickDate, startRange)){
        setStartRange(clickDate)
      } else {
        setEndRange(setDate(displayValue, index))
      }
    }
  };

  const isEndDate = (date: number) => {
    if(endRange === null){
      return false
    } else {
      return date === endRange?.getDate() && displayValue?.getMonth() === endRange?.getMonth()
    }
  }

  const isStartDate = (date: number) => {
    return date === startRange?.getDate() && displayValue?.getMonth() === startRange?.getMonth()
  }

  const isBetween = (date: number) => {
    const renderDate = new Date(displayValue);
    renderDate.setDate(date)
    return !!endRange && isBefore(renderDate, endRange) && !!startRange && isAfter(renderDate, startRange)
  }

  return (
    <div className="w-[400px] border-t border-l absolute bg-white z-40" style={{right:'10vw', top: 170}}>
      <div className="flex justify-between">
        <RangeDateInput value={startRange ? startRange?.toDateString(): ''} label={'Start'}/>
        <RangeDateInput value={endRange ? endRange?.toDateString(): ''} label={'End'}/>
      </div>
      <div className="grid grid-cols-7 items-center justify-center text-center">
        <Cell onClick={prevYear}>{"<<"}</Cell>
        <Cell onClick={prevMonth}>{"<"}</Cell>
        <Cell className="col-span-3">{format(value, "LLLL yyyy")}</Cell>
        <Cell onClick={nextMonth}>{">"}</Cell>
        <Cell onClick={nextYear}>{">>"}</Cell>

        {weeks.map((week, index) => (
          <Cell key={index} className="text-xs font-bold uppercase">{week}</Cell>
        ))}

        {Array.from({ length: prefixDays }).map((_, index) => (
          <Cell key={index} />
        ))}

        {Array.from({ length: numDays }).map((_, index) => {
          const date = index + 1;
          const isCurrentDate = isEndDate(date)
          || isStartDate(date)

          return (
            <Cell
              key={date}
              isActive={isCurrentDate}
              isBetween={isBetween(date)}
              onClick={() => handleClickDate(date)}
            >
              {date}
            </Cell>
          );
        })}

        {Array.from({ length: suffixDays }).map((_, index) => (
          <Cell key={index} />
        ))}
      </div>
    </div>
  );
};

export default Calendar;
