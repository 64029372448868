import { configureStore } from '@reduxjs/toolkit'
import ddts, { ddtTablePageSlice } from 'pages/DdtPage/slices'

export const store = configureStore({
  reducer: {
    ddtTablePage: ddtTablePageSlice.reducer,
    ddts: ddts,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})
