import { ReactComponent as LeftArrow } from 'images/icons/left-arrow.svg'
import { ReactComponent as RightArrow } from 'images/icons/right-arrow.svg'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { setPage } from 'pages/DdtPage/slices'
import { ReduxState } from 'redux/ReduxState'
import PaginationItem from 'components/Pagination/PaginationItem'
import { useState } from 'react'


export default function Pagination(props: any) {
  const {className} = props
  const dispatch = useDispatch()

  const ddtTablePageResponse = useSelector((state: ReduxState) => state.ddts.data.pageStatistics)
  const page = useSelector((state: ReduxState) => state.ddtTablePage)

  const {firstResultIndex, lastResultIndex, totalResults, totalPages} = ddtTablePageResponse

  //TODO handle the case when firstPage should be shifted back or forward
  const [firstPage] = useState(1)

  const nextPage = () => page + 1 <=totalPages ? dispatch(setPage(page + 1)) : ''
  const prevPage = () => page - 1 >= 1 ? dispatch(setPage(page - 1)) : ''

  return (
    <div
      className={clsx("flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 w-full", className)}>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{firstResultIndex}</span> to <span className="font-medium">{lastResultIndex}</span> of{' '}
            <span className="font-medium">{totalResults}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              className="no-underline relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={prevPage}
            >
              <span className="sr-only">Previous</span>
              <LeftArrow className="h-3 w-5" aria-hidden="true"/>
            </button>
            {
              Array.from({length: totalPages}, (_, i) => i + firstPage)
                .map(pageNumber => <PaginationItem key={pageNumber} index={pageNumber} isActive={pageNumber === page}/>)
            }
            <button
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={nextPage}
            >
              <RightArrow className="h-3 w-5" aria-hidden="true"/>
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}
