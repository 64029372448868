import { Container } from './Container'

import { ReactComponent as DdtLogo } from '../images/icons/ddtLogo.svg'
import { ReactComponent as Profile } from '../images/icons/person.svg'
import { Link, useMatch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import clsx from 'clsx'

export function Header() {
  const auth0 = useAuth0()
  const logout = () => auth0.logout()

  return (
    <header className="shrink-0">
      <Container className="">
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              <DdtLogo className="h-10 m-8 w-auto"/>
            </Link>
            <div className={clsx(useMatch('/query') ? "border-cyan-500": "border-transparent", "hidden md:flex border-b-4 md:gap-x-6 h-full")}>
              <Link className="flex items-center rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 no-underline"
                    to="/query">Query</Link>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <Profile className="w-5" onClick={logout}/>
          </div>
        </nav>
      </Container>
      <hr className="mt-0"/>
    </header>
  )
}
