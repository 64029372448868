//Generates pending, fulfilled and rejected action types
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFilesTableView } from 'pages/DdtPage/FileRecord/fileRecordApi'
import { DdtTableView } from 'pages/DdtPage/DdtTableView'
import { PageStatistics } from 'pages/DdtPage/PageStatistics'
import { ReduxState } from 'redux/ReduxState'

export type FetchDdtsResponseType = {
  tableViewList: DdtTableView[]
  pageStatistics: PageStatistics
}

export const fetchDdts = createAsyncThunk('ddts/fetchDdts', (page: number | undefined, thunkApi) => {
  // @ts-ignore
  const state: ReduxState = thunkApi.getState()
  const storedPage = state.ddtTablePage
  const filters = state.ddts.filters
  // The pages are zero based
  return getFilesTableView(storedPage - 1, filters.searchFilter, filters.userFilter, filters.startDate).then(r => {
    const data = r.data
    const totalPages = data.totalPages
    const firstResultIndex = data.number * data.size + 1
    const lastResultIndex = data.number * data.size + data.numberOfElements
    const totalResults = data.totalElements
    return {
      tableViewList: data.content.map((item: any) => {
        return {
          ...item,
          submittedDate: item.uploadDate
        }
      }),
      pageStatistics: {
        totalPages,
        firstResultIndex,
        lastResultIndex,
        totalResults
      }
    }
  });
}, {})
