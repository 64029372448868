import { ReactComponent as SearchIcon } from 'images/icons/search.svg'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'redux/ReduxState'
import { searchFilterSlice } from 'pages/DdtPage/FilterBlock/slices'
import React from 'react'

export default function SearchInput(props: any) {
  const {label, isShortcutAvailable} = props

  const searchInput = useSelector((state: ReduxState) => state.ddts.filters.searchFilter)
  const dispatch = useDispatch()
  function setInputChange(event: React.FormEvent<HTMLInputElement>){
    const inputValue = event.currentTarget.value
    dispatch(searchFilterSlice.actions.setTextInput(inputValue))
  }

  return (
    <div>
      {label && <label htmlFor="search" className="block text-sm font-medium text-gray-700">
        {label}
      </label>}
      <div className="relative mt-1 flex items-center">
        <div className="absolute left-0 flex pl-3">
          <SearchIcon/>
        </div>
        <input
          type="text"
          name="search"
          id="search"
          className="block rounded-md border-gray-300 pr-12 pl-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Search..."
          value={searchInput}
          onChange={setInputChange}
        />
        {isShortcutAvailable && <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
          <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
            ⌘K
          </kbd>
        </div>}
      </div>
    </div>
  )
}
