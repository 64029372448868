import styles from 'pages/DdtPage/FileRecord/FileRecord.module.scss'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { removeFile } from 'pages/DdtPage/FileRecord/fileRecordApi'
import { ReactComponent as RemoveIcon } from 'images/icons/trash.svg'
import clsx from 'clsx'
import FileRemoveModal from 'pages/DdtPage/FileRecord/FileRecordRemove/FileRemoveModal'

export const FileRecordRemove = (props: any) => {
  const {sse, onRemove, id, className, fileName} = props

  const [isLoading, setIsLoading] = useState(false)

  const removeFileRecord = () => {
    sse?.close()
    setIsLoading(true)
    removeFile(id).then(() => {
      onRemove()
      setIsLoading(false)
    })
  }

  const [isModalOpened, setModalOpened] = useState(false)

  return (
    isLoading
      ? <FontAwesomeIcon className={clsx(className, styles.closeButton)}
                         icon={solid('spinner')}/>
      : (
        <>
          <RemoveIcon className={className} onClick={() => setModalOpened(true)}/>
          {isModalOpened && <FileRemoveModal fileName={fileName} removeFileRecord={removeFileRecord}/>}
        </>
      )
  )
}
