import React, { useEffect, useState } from 'react'
import { FileRecordProcessingStatus } from 'pages/DdtPage/FileRecord/FileRecordProcessingStatus'
import { createSseForFileProcessing } from 'pages/DdtPage/FileRecord/fileRecordApi'
import { FileRecordRemove } from 'pages/DdtPage/FileRecord/FileRecordRemove/FileRecordRemove'
import { Download } from 'pages/DdtPage/FileRecord/Download/DownloadIcon'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


export const FileRecord = (props: any) => {
  const {id, fileName, version, submittedBy, submittedDate, retrieveFileRecords, bodyList} = props;
  const [processingStatus, setProcessingStatus] = useState(props.processingStatus)
  const [errorMessage, setErrorMessage] = useState(props.errorMessage)
  const [sse, setSse] = useState<EventSource>()

  useEffect(() => {
    const isEndingStatus = processingStatus === FileRecordProcessingStatus.PROCESSED
      || processingStatus === FileRecordProcessingStatus.ERROR
    if (id && !isEndingStatus) {
      const eventSource = createSseForFileProcessing(id, setProcessingStatus, setErrorMessage)
      setSse(eventSource)
    }
  }, [id, processingStatus])

  const formText = (body: any) => {
    const bodyStyles = body.bodyStyles.join(', ')
    return `Applies to ${body.oem} ${body.year} ${bodyStyles}`
  }

  return (
    <tr key={id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="font-medium text-gray-900">{fileName}</div>
        {bodyList && bodyList.map((body: any) => {
          return <div key={formText(body)} className="text-gray-500">{formText(body)}</div>
        })}

      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {version}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-900">{submittedBy}</div>
      </td>
      <td
        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(submittedDate).format('MMMM Do YYYY')}</td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6
      flex align-items-center justify-content-center">
        {processingStatus === FileRecordProcessingStatus.PROCESSED && (<>
          <div className="inline-flex">
            <Download className="m-3 cursor-pointer" id={id}/>
            <FileRecordRemove className="m-3 cursor-pointer" sse={sse} id={id} onRemove={retrieveFileRecords}
                              fileName={fileName}/>
          </div>
        </>)}
        {processingStatus === FileRecordProcessingStatus.DRAFT
          && <FontAwesomeIcon className="m-3 w-5 h-5" icon={solid('note-sticky')}/>}
        {processingStatus === FileRecordProcessingStatus.PROCESSING
          && (
            <div className="inline-flex ">
              <FontAwesomeIcon className="m-3 w-5 h-5" icon={solid('spinner')}/>
            </div>
          )}
        {processingStatus === FileRecordProcessingStatus.ERROR
          && <FontAwesomeIcon className="m-3 w-5 h-5"
                              title={errorMessage}
                              icon={solid('triangle-exclamation')}/>}
      </td>
    </tr>
  );
}
