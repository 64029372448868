import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import QueryPage from 'pages/QueryPage/QueryPage'

import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth0 } from '@auth0/auth0-react'
import { apiTokenWrapper } from 'api/ApiTokenWrapper'
import DdtPage from 'pages/DdtPage/DdtPage'
import { Provider } from 'react-redux'
import { store } from 'redux/store'
import FeedbackPage from 'pages/FeedbackPage/FeedbackPage'

function App() {
  const {isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently} = useAuth0();
  const [tokenGeneratorIsSet, setTokenGeneratorIsSet] = useState(false)
  useEffect(() => {
    if (isAuthenticated) {
      // Set getAccessTokenSilently to a service
      apiTokenWrapper.tokenGenerator = getAccessTokenSilently
      setTokenGeneratorIsSet(true)
    }
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect().then();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently, setTokenGeneratorIsSet])
  return (
    tokenGeneratorIsSet ?
      (<Provider store={store}>
          <div className="App">
            <BrowserRouter>
              <Routes>
                <Route path="/">
                  <Route index element={<DdtPage/>}/>
                  <Route path="query" element={<QueryPage/>}/>
                  <Route path="feedback" element={<FeedbackPage/>}/>
                  {/*<Route path="*" element={<NoPage />} />*/}
                </Route>
              </Routes>
            </BrowserRouter>
          </div>
        </Provider>
      ) : <></>
  );
}

export default App;
