import { Client } from '@notionhq/client'

const notion = new Client({
    auth: "secret_r2yUDM8dZ1PLnEApo5KJMs0yICcSBH4pfgoMietkNPo"
});

const DATABASE_ID = "0140d22065a64674a8545bc42a6eaf2f";

export function sendFeedback() {

    notion.pages.create({
        parent: {
            database_id: DATABASE_ID
        },
        icon: {
            emoji: "📝"
        },
        properties: {
            Name: {
                title: [
                    {
                        text: {
                            content: "Lorem Ipsum"
                        }
                    }
                ]
            },
            Application: {
                rich_text: [
                    {
                        type: "text",
                        text: {
                            content: "DDT Library"
                        }
                    }
                ]
            },
            "Created at": {
                date: {
                    start: new Date().toISOString()
                }
            },
            Environment: {
                rich_text: [
                    {
                        type: "text",
                        text: {
                            content: "PROD"
                        }
                    }
                ]
            },
            Priority: {
                select: {
                    id: "ZjBf",
                    name: "Medium",
                    color: "yellow"
                }
            },
            Stage: {
                select: {
                    id: "U_h}",
                    name: "Backlog",
                    color: "gray"
                }
            },
            Reporter: {
                rich_text: [
                    {
                        type: "text",
                        text: {
                            content: "Joe Technician"
                        }
                    }
                ]
            },
            Version: {
                rich_text: [
                    {
                        type: "text",
                        text: {
                            content: "2022.12.9"
                        }
                    }
                ]
            },
            Description: {
                rich_text: [
                    {
                        type: "text",
                        text: {
                            content: "Long description goes here"
                        }
                    }
                ]
            }
        },
        children: [
            {
                object: "block",
                type: "heading_2",
                heading_2: {
                    rich_text: [
                        {
                            type: "text",
                            text: {
                                content: "Here are examples of the types of blocks that may be added"
                            }
                        }
                    ]
                }
            },
            {
                object: "block",
                type: "paragraph",
                paragraph: {
                    rich_text: [
                        {
                            type: "text",
                            text: {
                                content: "This is a paragraph block.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                            }
                        }
                    ],
                    color: "yellow_background"
                }
            },
            {
                object: "block",
                type: "callout",
                callout: {
                    rich_text: [
                        {
                            type: "text",
                            text: {
                                content: "This is a callout block.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                            }
                        }
                    ],
                    color: "red",
                    icon: {
                        emoji: "⭐"
                    }
                }
            },
            {
                object: "block",
                type: "quote",
                quote: {
                    rich_text: [
                        {
                            type: "text",
                            text: {
                                content: "This is a quote block.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                            }
                        }
                    ],
                    color: "blue"
                }
            },
            {
                object: "block",
                type: "code",
                code: {
                    rich_text: [
                        {
                            type: "text",
                            text: {
                                content: "This is a code block.\n\nconst a = 3"
                            }
                        }
                    ],
                    language: "javascript"
                }
            },
            {
                object: "block",
                type: "image",
                image: {
                    type: "external",
                    external: {
                        url: "https://img.icons8.com/ios/250/000000/air-jordan.png"
                    }
                }
            }
        ]
    }).then();
}
