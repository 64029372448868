import Table from './Table'
import Page from 'components/Page'

export default function DdtPage() {
  return (
    <Page>
      <Table className="grow"/>
    </Page>
  )
}
