import { setPage } from 'pages/DdtPage/slices'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

export default function PaginationItem(props: any) {
  const {classNames, index, isActive} = props
  const dispatch = useDispatch()
  const inactiveClassNames = "border border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
  const activeClassNames = "z-10 border-indigo-500 bg-gray-100 text-gray-600"

  return (
    <button
      className={clsx(
        classNames,
        isActive ? activeClassNames : inactiveClassNames,
        "hover:text-gray-600 focus:z-20 inline-flex items-center border no-underline relative px-4 py-2 text-sm " +
        "font-medium"
      )}
      onClick={() => dispatch(setPage(index))}
    >
      {index}
    </button>
  )
}


